<template>
  <div class="upload" ref="upload">
    <a-upload
      list-type="picture-card"
      class="avatar-uploader"
      :accept="accept"
      :show-upload-list="false"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <template v-if="(thumbnail === '' || img === '') && upload.upState !== 0">
        <div class="upload-btn">
          <a-icon :type="upload.loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">点击上传</div>
        </div>
      </template>
      <template
        v-else-if="
          thumbnail || img || (upload && upload.file && upload.file.type)
        "
      >
        <div class="upload-file">
          <div class="handle-btn" @click="(e) => e.stopPropagation()">
            <a-icon v-if="del" type="delete" @click="upload_handle('delete')" />
          </div>
          <template
            v-if="thumbnail || img || upload.file.type.indexOf('image') !== -1"
          >
            <div class="upload-image">
              <Image-Zoom
                :img="
                  upload.fileBase64
                    ? upload.fileBase64
                    : `${$axios.defaults.baseURL}/${
                        thumbnail ? thumbnail : img ? img : ''
                      }`
                "
                :thumbnail="
                  upload.fileBase64
                    ? upload.fileBase64
                    : `${$axios.defaults.baseURL}/${
                        thumbnail ? thumbnail : img ? img : ''
                      }`
                "
                :zoom="true"
              />
            </div>
          </template>
        </div>
      </template>
    </a-upload>
  </div>
</template>

<script>
import Vue from "vue";
import { Modal, Upload } from "ant-design-vue";
Vue.use(Modal).use(Upload);

import ImageZoom from "@/components/Image-Zoom";

export default {
  components: {
    ImageZoom,
  },
  // 父组件在标签中传递过来的数据
  props: {
    alt: {
      type: String,
      default: "",
    },
    accept: {
      type: String,
      default: "image/jpeg",
    },
    img: {
      type: String,
      default: "",
    },
    thumbnail: {
      type: String,
      default: "",
    },
    zoom: {
      type: Boolean,
      default: false,
    },
    del: {
      type: Boolean,
      default: false,
    },
    file: {},
  },
  data: function () {
    return {
      upload: {
        upState: null,
        loading: false,
        file: {},
        fileBase64: "",
        fileHistoryList: [],
      },
      imageZoom: this.zoom,
      modal: {
        show: false,
      },
      modal_img_touch_info: { screenX: 0, screenY: 0 },
      test: [],
      distance: {
        a: 0, //
        b: 0,
        c: 0,
      },
    };
  },
  methods: {
    setElementInit() {
      const a = setInterval(() => {
        const dom = this.$refs["upload"];
        if (dom) {
          this.setElementInit = () => {
            const { clientWidth, clientHeight } = dom;

            const size =
              clientWidth < clientHeight
                ? clientWidth + "px"
                : clientHeight + "px";

            dom.style.width = size;
            dom.style.height = size;
          };
          this.setElementInit();
          clearInterval(a);
        }
      }, 50);
    },
    getFileBase64(fileData) {
      const reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;
        img.onload = () => {
          this.upload.file.width = img.width;
          this.upload.file.height = img.height;
        };
        this.upload.fileBase64 = reader.result;
        this.upload.loading = false;
      };
      fileData && reader.readAsDataURL(fileData);
    },
    beforeUpload(fileData) {
      this.upload.file = fileData;
      this.upload.loading = true;
      this.imageZoom = true;

      this.upload.fileHistoryList.push(fileData);
      return false;
    },
    handleChange(fileData) {
      const file_image = new FormData();
      file_image.append("file", fileData.file, fileData.file.name);

      this.$axios
        .post("upload/identity-auth", file_image, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.upload.upState = res.code;
          this.upload.loading = false;
          if (res.code === 0) {
            this.imageZoom = true;
            this.getFileBase64(this.upload.file);
            this.$emit("update:file", this.upload.file);
          }
        });
    },
    upload_handle(handle) {
      switch (handle) {
        case "delete":
          this.upload.upState = null;
          this.upload.file = {};
          this.upload.fileBase64 = "";
          break;
      }
    },
    modal_img_touch(e, handle) {
      e.stopPropagation(); // 阻止事件冒泡
      if (1 < e.touches.length) e.preventDefault(); // 阻止事件下沉

      const dom = e.target;
      console.log(dom.naturalWidth);

      function getDistance(a) {
        return Math.sqrt(
          Math.pow(a[0].screenX - a[1].screenX, 2) +
            Math.pow(a[0].screenY - a[1].screenY, 2)
        );
      }

      switch (handle) {
        case "start":
          if (1 < e.touches.length) this.distance.a = getDistance(e.touches);

          break;
        case "move":
          if (1 < e.touches.length) {
            this.distance.b = getDistance(e.touches);
            this.distance.c = this.distance.b - this.distance.a;
          }

          break;
        case "end":
          this.distance.a = 0;
          this.distance.b = 0;
          this.distance.c = 0;

          break;
      }
    },
  },
  mounted() {
    this.setElementInit();
  },
};
</script>

<style lang="less">
.upload {
  width: 100%;
  height: 100%;
  min-width: 12em;
  min-height: 12em;

  .avatar-uploader,
  .ant-upload {
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .upload-image {
    height: 100%;
  }

  .upload-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    font-size: 1em;
    line-height: 1.6em;
  }

  .ant-upload {
    position: relative;
  }

  .upload-file {
    height: 100%;
    position: relative;

    > .handle-btn {
      position: absolute;
      top: 0;
      right: 0;
      height: 0 !important;
    }

    *:not(.handle-btn *, img):not(#image-full, #image-full *) {
      height: 100%;
      max-height: 100%;
    }

    .handle-btn {
      height: 0;

      > i {
        position: absolute;
        z-index: 1;
        display: flex;
        justify-content: center;
        transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%); /* Opera */
        -ms-transform: translate(-50%, -50%); /* IE 9 */
        -moz-transform: translate(-50%, -50%); /* Firefox */
        -webkit-transform: translate(-50%, -50%); /* Safari 和 Chrome */
        font-size: 2em;

        border-radius: 50%;
        padding: 0.3em;
        background-color: rgba(0, 0, 0, 0.4);

        &.anticon-delete {
          top: 100%;
          left: 100%;
          color: red;
        }

        &.anticon-fullscreen {
          top: 0%;
          left: 100%;
        }
      }
    }
  }
}

.modal-content {
  overflow: auto;
  width: 100%;
  height: 100%;

  * {
    max-width: unset;
    max-height: unset;
  }
}

.ant-modal-content {
  height: 100% !important;

  .ant-modal-body {
    overflow: hidden !important;
    margin-top: calc(55px - 1em) !important;

    > div:first-child:not(.ant-form-item) {
      margin-top: 0 !important;
    }
  }
}

.modal-image-full {
  .ant-modal {
    // max-width: ;
    max-height: 90%;

    > .ant-modal-content {
      height: 100% !important;
      max-height: 90vh !important;

      > .ant-modal-body {
        height: 100% !important;
        max-height: 100% !important;
        overflow: hidden;
        // position: relative;

        > .modal-content {
          // position: absolute;
          // top: 0;
          // left: 0;
          // width: 100%;
          // height: 100%;
        }
      }
    }
  }
  .modal-body > img {
    // width: 100%;
    // height: 100%;
  }
}
</style>