var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Real-Name-Authentication"}},[_c('div',{staticClass:"real-name-authentication-body"},[_c('a-form-model',_vm._b({ref:"formModel",attrs:{"model":_vm.formModel.item}},'a-form-model',{
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
      },false),[_c('a-form-model-item',[_c('a-button',{on:{"click":function($event){return _vm.$router.push({ name: 'Hint-Updata' })}}},[_vm._v(" 查看实名认证示例 ")])],1),_c('a-form-model-item',[_c('div',{staticClass:"identity-info"},[_c('div',{staticClass:"identity-image",style:({
              width: '100%',
              height: '0',
              paddingBottom: '100%',
              maxWidth: '100%',
              maxHeight: '100%',
              position: 'relative',
            })},[_c('div',{style:({ width: '100%', height: '100%', position: 'absolute' })},[_c('Upload',{attrs:{"img":_vm.identity_info.certified_image
                    ? _vm.identity_info.certified_image
                    : _vm.identity_info.thumbnail_image
                    ? _vm.identity_info.thumbnail_image
                    : _vm.identity_info.temp_image,"thumbnail":_vm.identity_info.thumbnail_image
                    ? _vm.identity_info.thumbnail_image
                    : _vm.identity_info.temp_image,"zoom":true || Boolean(_vm.identity_info.certified_image),"file":_vm.formModel.item.file},on:{"update:file":function($event){return _vm.$set(_vm.formModel.item, "file", $event)}}})],1),_c('p',{style:({
                position: 'absolute',
                bottom: '-0.6em',
                width: '100%',
                textAlign: 'center',
              })},[_c('span',{style:({
                  backgroundColor: '#3d447baa',
                  color: '#aaa',
                  height: '1.2em',
                  lineHeight: '1.2em',
                  fontSize: '0.8em !important',
                  borderRadius: '50%',
                })},[_vm._v(" （点击上方虚线框上传or修改图片） ")])])]),_c('div',{staticClass:"identity-status"},[_c('img',{attrs:{"src":`${_vm.$public}/images/status/check-${
                _vm.identity_info.examine_status === 1
                  ? 'success'
                  : _vm.identity_info.examine_status === -1
                  ? 'error'
                  : _vm.identity_info.examine_status === 0
                  ? 'warning'
                  : 'unverified'
              }.png`,"error-disabled":"","alt":""}})]),_c('div',{staticClass:"identity-message",style:({ textAlign: 'center' })},[(_vm.identity_info.remark)?_c('p',[_c('span',[_vm._v("提示：")]),_c('span',[_vm._v(_vm._s(_vm.identity_info.remark))])]):_vm._e()])])]),_c('a-form-model-item',[_c('div',{style:({ display: 'flex' }),attrs:{"slot":"label"},slot:"label"},[_c('span',[_vm._v("请输入 FIL 、 USDT 、ALEO 的提币地址")]),_c('span',[_c('router-link',{attrs:{"to":{ name: 'Hint-Paste' }}},[_vm._v("粘贴教程")])],1)]),_c('a-textarea',{attrs:{"autocomplete":"off","placeholder":'请输入 FIL 提币地址'},model:{value:(_vm.formModel.item.fil_withdraw_address),callback:function ($$v) {_vm.$set(_vm.formModel.item, "fil_withdraw_address", $$v)},expression:"formModel.item.fil_withdraw_address"}})],1),_c('a-form-model-item',[_c('a-textarea',{attrs:{"autocomplete":"off","placeholder":'请输入 USDT(TRC20链) 提币地址'},model:{value:(_vm.formModel.item.usdt_withdraw_address),callback:function ($$v) {_vm.$set(_vm.formModel.item, "usdt_withdraw_address", $$v)},expression:"formModel.item.usdt_withdraw_address"}})],1),_c('a-form-model-item',[_c('a-textarea',{attrs:{"autocomplete":"off","placeholder":'请输入 ALEO 提币地址'},model:{value:(_vm.formModel.item.aleo_withdraw_address),callback:function ($$v) {_vm.$set(_vm.formModel.item, "aleo_withdraw_address", $$v)},expression:"formModel.item.aleo_withdraw_address"}})],1),_c('a-form-model-item',{ref:"form-btn",staticClass:"form-btn"},[_c('a-button',{on:{"click":_vm.onSubmit}},[_vm._v(" 提交审核 ")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }