var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"upload",staticClass:"upload"},[_c('a-upload',{staticClass:"avatar-uploader",attrs:{"list-type":"picture-card","accept":_vm.accept,"show-upload-list":false,"before-upload":_vm.beforeUpload},on:{"change":_vm.handleChange}},[((_vm.thumbnail === '' || _vm.img === '') && _vm.upload.upState !== 0)?[_c('div',{staticClass:"upload-btn"},[_c('a-icon',{attrs:{"type":_vm.upload.loading ? 'loading' : 'plus'}}),_c('div',{staticClass:"ant-upload-text"},[_vm._v("点击上传")])],1)]:(
        _vm.thumbnail || _vm.img || (_vm.upload && _vm.upload.file && _vm.upload.file.type)
      )?[_c('div',{staticClass:"upload-file"},[_c('div',{staticClass:"handle-btn",on:{"click":(e) => e.stopPropagation()}},[(_vm.del)?_c('a-icon',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.upload_handle('delete')}}}):_vm._e()],1),(_vm.thumbnail || _vm.img || _vm.upload.file.type.indexOf('image') !== -1)?[_c('div',{staticClass:"upload-image"},[_c('Image-Zoom',{attrs:{"img":_vm.upload.fileBase64
                  ? _vm.upload.fileBase64
                  : `${_vm.$axios.defaults.baseURL}/${
                      _vm.thumbnail ? _vm.thumbnail : _vm.img ? _vm.img : ''
                    }`,"thumbnail":_vm.upload.fileBase64
                  ? _vm.upload.fileBase64
                  : `${_vm.$axios.defaults.baseURL}/${
                      _vm.thumbnail ? _vm.thumbnail : _vm.img ? _vm.img : ''
                    }`,"zoom":true}})],1)]:_vm._e()],2)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }