<template>
  <div id="Real-Name-Authentication">
    <div class="real-name-authentication-body">
      <a-form-model
        ref="formModel"
        v-bind="{
          labelCol: { span: 0 },
          wrapperCol: { span: 24 },
        }"
        :model="formModel.item"
      >
        <a-form-model-item>
          <a-button @click="$router.push({ name: 'Hint-Updata' })">
            查看实名认证示例
          </a-button>
        </a-form-model-item>
        <a-form-model-item>
          <div class="identity-info">
            <div
              class="identity-image"
              :style="{
                width: '100%',
                height: '0',
                paddingBottom: '100%',
                maxWidth: '100%',
                maxHeight: '100%',
                position: 'relative',
              }"
            >
              <div
                :style="{ width: '100%', height: '100%', position: 'absolute' }"
              >
                <Upload
                  :img="
                    identity_info.certified_image
                      ? identity_info.certified_image
                      : identity_info.thumbnail_image
                      ? identity_info.thumbnail_image
                      : identity_info.temp_image
                  "
                  :thumbnail="
                    identity_info.thumbnail_image
                      ? identity_info.thumbnail_image
                      : identity_info.temp_image
                  "
                  :zoom="true || Boolean(identity_info.certified_image)"
                  :file.sync="formModel.item.file"
                />
              </div>
              <p
                :style="{
                  position: 'absolute',
                  bottom: '-0.6em',
                  width: '100%',
                  textAlign: 'center',
                }"
              >
                <span
                  :style="{
                    backgroundColor: '#3d447baa',
                    color: '#aaa',
                    height: '1.2em',
                    lineHeight: '1.2em',
                    fontSize: '0.8em !important',
                    borderRadius: '50%',
                  }"
                >
                  （点击上方虚线框上传or修改图片）
                </span>
              </p>
            </div>
            <div class="identity-status">
              <img
                :src="`${$public}/images/status/check-${
                  identity_info.examine_status === 1
                    ? 'success'
                    : identity_info.examine_status === -1
                    ? 'error'
                    : identity_info.examine_status === 0
                    ? 'warning'
                    : 'unverified'
                }.png`"
                error-disabled
                alt=""
              />
            </div>
            <div class="identity-message" :style="{ textAlign: 'center' }">
              <p v-if="identity_info.remark">
                <span>提示：</span>
                <span>{{ identity_info.remark }}</span>
              </p>
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item>
          <div slot="label" :style="{ display: 'flex' }">
            <span>请输入 FIL 、 USDT 、ALEO 的提币地址</span>
            <span>
              <router-link :to="{ name: 'Hint-Paste' }">粘贴教程</router-link>
            </span>
          </div>
          <a-textarea
            v-model="formModel.item.fil_withdraw_address"
            autocomplete="off"
            :placeholder="'请输入 FIL 提币地址'"
          />
        </a-form-model-item>
        <a-form-model-item>
        <a-textarea
            v-model="formModel.item.usdt_withdraw_address"
            autocomplete="off"
            :placeholder="'请输入 USDT(TRC20链) 提币地址'"
          />
        </a-form-model-item>
        <a-form-model-item>
        <a-textarea
            v-model="formModel.item.aleo_withdraw_address"
            autocomplete="off"
            :placeholder="'请输入 ALEO 提币地址'"
          />
        </a-form-model-item>
        <a-form-model-item class="form-btn" ref="form-btn">
          <a-button @click="onSubmit"> 提交审核 </a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import "@/components/js/_form_common";

import Upload from "@/components/Upload";

export default {
  components: {
    Upload,
  },
  data: function () {
    return {
      identity_info: {},
      formModel: {
        item: {
          file: {},
          fil_withdraw_address: "",
        },
      },
    };
  },
  methods: {
    async get_identity_info() {
      await this.$axios.get("personal-center/identity-info").then((res) => {
        if (res.code === 0) {
          this.identity_info = res.data;
          this.formModel.item.fil_withdraw_address = this.identity_info.fil_withdraw_address;
          this.formModel.item.usdt_withdraw_address = this.identity_info.usdt_withdraw_address;
          this.formModel.item.aleo_withdraw_address = this.identity_info.aleo_withdraw_address;
        }
      });
    },
    onSubmit() {
      this.$message.destroy();
      this.$message.warning("实名认证提交中，请勿操作");

      const dom_btn = this.$refs["form-btn"].$el.getElementsByTagName("button");
      for (let i in [...dom_btn]) {
        dom_btn[i].style["pointer-events"] = "none";
        dom_btn[i].setAttribute("disabled", "disabled");
      }

      this.$axios
        .post(
          "personal-center/identity-auth-submit",
          this.$axios.qs.stringify({
            fil_withdraw_address: this.formModel.item.fil_withdraw_address,
            usdt_withdraw_address: this.formModel.item.usdt_withdraw_address,
            aleo_withdraw_address: this.formModel.item.aleo_withdraw_address,
          })
        )
        .then((res) => {
          this.$message.destroy();
          if (res.code === 0) {
            this.$message.success("实名认证信息已提交，等待审核中。。。");
            setTimeout(window.location.reload(), 1500);
          } else {
            this.$message.warning("实名认证信息提交失败");
            this.$message.warning(res.code);
            this.$message.warning(res.message);

            for (let i in [...dom_btn]) {
              dom_btn[i].style["pointer-events"] = "auto";
              dom_btn[i].removeAttribute("disabled");
            }
          }
        });
    },
  },
  created() {
    this.get_identity_info();
  },
};
</script>

<style lang="less">
#Real-Name-Authentication {
  // background-color: red;
  display: flex;
  justify-content: center;
  align-content: center;

  .image-zoom .image-transform {
    // padding-bottom: 50%;
  }

  .upload {
    .ant-upload {
      background-color: inherit;
    }
  }

  .identity-info {
    position: relative;

    .identity-status {
      position: absolute;
      top: -30px;
      left: -30px;

      > img {
        width: 120px;
        height: 120px;
        transform: rotate(-20deg);
      }
    }
  }
}
</style>